<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>{{ $t(`settings.language.${languageTitleString}`) }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <treeselect
        v-model="currentLanguageId"
        :multiple="false"
        :options="allAvailableLanguages"
        :clearable="false"
        :placeholder="$t('settings.language.selectPlaceholder')"
        @select="changeLanguage"
      />
    </b-col>
  </b-row>
</template>

<script>
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import GetAllAvailableLanguages from '@/graphQlQueries/queries/getAllAvailableLanguages';

export default {
  name: 'LanguageSelect',
  components: { Treeselect: () => import('@riophae/vue-treeselect') },
  props: {
    languageId: Number,
    languageTitleString: {
      type: String,
      default: 'itemLanguage',
    },
  },
  data: () => ({
    // if the localeId is null use the localId of english USA
    currentLanguageId: null,
    allAvailableLanguages: [],
  }),
  apollo: {
    allAvailableLanguages: {
      query: GetAllAvailableLanguages,
      update(data) {
        const allLanguagesArray = [];
        data.languages.forEach((languagesItem) => {
          allLanguagesArray.push({
            id: languagesItem.language_id,
            label: languagesItem.name,
            name: languagesItem.handle,
          });
        });
        return allLanguagesArray;
      },
    },
  },
  created() {
    if (this.languageId) {
      this.currentLanguageId = this.languageId;
    }
  },
  methods: {
    async changeLanguage(item) {
      this.$emit('language-changed', item);
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .vue-treeselect__control {
  background: #5b6064;
  border-color: #000;

  .vue-treeselect__single-value {
    color: #fff;
  }
}
</style>
