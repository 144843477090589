var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
        _c("label", [
          _vm._v(_vm._s(_vm.$t(`settings.language.${_vm.languageTitleString}`)))
        ])
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12", sm: "7" } },
        [
          _c("treeselect", {
            attrs: {
              multiple: false,
              options: _vm.allAvailableLanguages,
              clearable: false,
              placeholder: _vm.$t("settings.language.selectPlaceholder")
            },
            on: { select: _vm.changeLanguage },
            model: {
              value: _vm.currentLanguageId,
              callback: function($$v) {
                _vm.currentLanguageId = $$v
              },
              expression: "currentLanguageId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }